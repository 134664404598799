import React from "react";
import './ConvoSearchBar.css'

const ConvoSearchBar = () => {
  return (
    <div className="convo-search-bar">
    </div>
  )
}

export default ConvoSearchBar;